<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <h2 class="d-flex justify-content-center">
        Tabel Kabupaten/Kota
      </h2>
      <div class="row mb-8 mt-8 d-flex justify-content-center">
        <apexchart
          ref="myChart"
          type="bar"
          height="350"
          width="900"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-custom gutter-b card-stretch">
            <!--begin::Body-->
            <div class="card-body">
              <button class="btn btn-secondary mb-7" @click="$router.back()">
                <i class="fa fa-arrow-left"></i>Kembali
              </button>
              <h2 class="text-center">Provinsi {{ this.provinsis.name }}</h2>
              <hr />
              <div class="justify-content-between d-flex">
                <div class="d-flex">
                  <b-form
                    class="mr-5"
                    style="width: 300px; border-radius: 4px;"
                    v-on:submit.prevent
                  >
                    <div
                      class="input-group input-group-solid input-group-sm"
                      style="background: #DAE1EC; height: 38px"
                    >
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="search"
                        autocomplete="off"
                        placeholder="Nomenklatur"
                        v-model="search"
                        ref="search"
                        @keyup.enter="searchFilterKabKotaStats()"
                      />
                      <button
                        id="button-addon2"
                        class="btn btn-cari"
                        type="button"
                        @click="searchFilterKabKotaStats"
                      >
                        <span v-if="!loading"
                          ><span class="svg-icon svg-icon-sm">
                            <inline-svg src="/media/svg/custom/search.svg" />
                          </span>
                        </span>
                        <b-spinner medium v-else></b-spinner>
                      </button>
                    </div>
                  </b-form>
                  <b-form class="mr-5">
                    <b-form-group
                      id="input-group-3"
                      label=""
                      label-for="input-3"
                    >
                      <b-form-select
                        id="input-3"
                        v-model="form.badan"
                        style="background-color: #dae1ec"
                        :options="badans"
                        @change="onSelectBadan"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >Bentuk Kelembagaan</b-form-select-option
                          >
                        </template>
                        <template #default>
                          <b-form-select-option :value="'0'"
                            >-</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-form>
                </div>
                <div class="d-flex">
                  <b-form class="mr-5">
                    <b-dropdown split text="Cetak" variant="secondary">
                      <b-dropdown-item href="#" @click="exportToExcel"
                        >Excel</b-dropdown-item
                      >
                      <b-dropdown-item href="#" @click="exportToPDF"
                        >PDF</b-dropdown-item
                      >
                    </b-dropdown>
                  </b-form>

                  <b-form>
                    <button
                      type="button"
                      class="hidden-button"
                      @click="refreshData"
                      id="refreshButton"
                      >
                      <i class="fas fa-sync-alt"></i>
                    </button>
                    <b-button
                      style="background: #0A65FF; border-radius: 4px; color: #ffffff; font-weight: 600"
                      @click.prevent.stop="showModalAdd = true"
                      :class="hasPermission('user/add') || hasPermission('kesbangpol/add') ? '' : 'hidden-button'"
                    >
                      <i class="fa fa-plus-circle" style="color: #ffffff;"></i
                      >Tambah Data</b-button
                    >
                  </b-form>
                </div>
              </div>
              <!--begin::Table-->
              <DxDataGrid
                :ref="dataGridRefKey"
                :data-source="dataSource"
                key-expr="id"
                :show-borders="true"
                :word-wrap-enabled="true"
                :column-auto-width="true"
                :remote-operations="true"
              >
                <DxScrolling
                  :use-native="true"
                  :scroll-by-content="true"
                  :scroll-by-thumb="true"
                  show-scrollbar="onHover"
                />
                <DxFilterRow :visible="true" />
                <DxColumn
                  data-field="kabkota.name"
                  caption="Daerah"
                  :filter-operations="['contains']"
                />
                <DxColumn
                  data-field="bentuk_kelembagaan.name"
                  caption="Bentuk Kelembagaan"
                  :allow-filtering="false"
                />
                <DxColumn
                  data-field="nomenklatur"
                  caption="Nomenklatur Kelembagaan"
                  :allow-filtering="false"
                />
                <DxColumn
                  data-field="alamat"
                  caption="Alamat Kantor"
                  :allow-filtering="false"
                />
                <DxColumn
                  data-field="nama_pimpinan"
                  caption="Nama Pimpinan"
                  :allow-filtering="false"
                />
                <DxColumn
                  data-field="no_telp"
                  caption="No. Telepon/Whatsapp Kantor"
                  :allow-filtering="false"
                />
                <DxColumn
                  data-field="email"
                  caption="Email Kantor"
                  :allow-filtering="false"
                />
                <DxColumn
                  data-field="keterangan"
                  caption="Keterangan"
                  :allow-filtering="false"
                />
                <DxColumn
                  type="buttons"
                  caption="Aksi"
                  :visible="hasPermission('system') || hasPermission('kesbangpol/update')"
                >
                  <DxButton
                    icon="fa fa-edit"
                    hint="Ubah"
                    :onClick="onClickEdit"
                  />
                  <DxButton
                    icon="fa fa-trash"
                    hint="Hapus"
                    :onClick="onClickDelete"
                  />
                </DxColumn>
                <DxPaging :page-size="10" />
                <DxPager
                  :visible="true"
                  :show-page-size-selector="showPageSizeSelector"
                  :allowed-page-sizes="pageSizes"
                />
              </DxDataGrid>
            </div>

            <!--   Modal Tambah Kelembagaan Kabupaten Kota  -->
            <b-modal
              v-model="showModalAdd"
              hide-footer
              size="xl"
              centered
              scrollable
              id="modal-tambah-kabkota"
            >
              <template #modal-header="{ close }">
                <h5 class="title-card-custom">
                  Tambah Kelembagaan Kabupaten Kota
                </h5>
              </template>
              <b-form @submit.stop.prevent="onSubmit">
                <b-form-group
                  id="example-input-group-3"
                  label-for="select-kabkota"
                  label="Kabupaten/Kota"
                  label-class="required"
                >
                  <b-form-select
                    id="select-kabkota"
                    v-model="form.kabkota"
                    :options="kabkotas"
                    required
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Pilih Salah Satu --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="example-input-group-badan"
                  label-for="select-badan"
                  label="Bentuk Kelembagaan"
                  label-class="required"
                >
                  <b-form-select
                    id="select-badan"
                    v-model="form.badan"
                    :options="badans"
                    required
                  >
                    <!-- This slot appears above the options from 'options' prop -->
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Pilih salah satu --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="nomenklatur-group"
                  label="Nomenklatur Kelembagaan"
                  label-for="nomenklatur"
                  label-class="required"
                >
                  <b-form-input
                    id="nomenklatur"
                    v-model="form.nomenklatur"
                    type="text"
                    placeholder="Nomenklatur"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="alamat-group"
                  label="Alamat Kantor"
                  label-for="alamat"
                  label-class="required"
                >
                  <b-form-textarea
                    id="alamat"
                    v-model="form.alamat"
                    placeholder="Alamat Kantor"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  id="nama-pimpinan-group"
                  label="Nama Pimpinan"
                  label-for="nama_pimpinan"
                  label-class="required"
                >
                  <b-form-input
                    id="nama_pimpinan"
                    v-model="form.nama_pimpinan"
                    type="text"
                    placeholder="Nama Pimpinan"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="no-telp-group"
                  label="No. Telepon/Whatsapp Kantor"
                  label-for="no_telp"
                  label-class="required"
                >
                  <b-form-input
                    id="no_telp"
                    v-model="form.no_telp"
                    type="tel"
                    placeholder="No. Telp."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="email-group" label="Email Kantor" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    type="email"
                    placeholder="Email Kantor"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-7"
                  label="Perda Pembentukan"
                  label-for="perda_pembentukan-7"
                  description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
                  label-class="required"
                >
                  <b-row>
                    <b-col cols="8">
                      <b-form-input
                        id="perda_pembentukan"
                        v-model="form.perda_pembentukan"
                        type="text"
                        placeholder="Perda Pembentukan"
                        required
                      ></b-form-input
                    ></b-col>
                    <b-col cols="4"
                      ><b-form-file
                        ref="files"
                        placeholder="Pilih File"
                        drop-placeholder="Drop file di sini..."
                        accept=".pdf, .doc, .docx"
                        browse-text="Cari"
                        multiple
                        :file-name-formatter="formatNames"
                        @change="
                          uploadPerdaPembentukan($event);
                          validateFileTypeGeneral($event);
                          fileSizeChecker('10', $event);
                        "
                        required
                      ></b-form-file
                    ></b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  id="struktur-organisasi-group"
                  label="Struktur Organisasi"
                  label-for="struktur-organisasi"
                  description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
                  label-class="required"
                >
                  <b-row>
                    <b-col cols="8">
                      <b-form-input
                        id="struktur_organisasi"
                        v-model="form.struktur_organisasi"
                        type="text"
                        placeholder="Struktur Organisasi"
                        required
                      ></b-form-input
                    ></b-col>
                    <b-col cols="4">
                      <b-form-file
                        ref="file"
                        placeholder="Pilih File"
                        drop-placeholder="Drop file di sini..."
                        accept=".pdf, .doc, .docx"
                        browse-text="Cari"
                        multiple
                        :file-name-formatter="formatNames"
                        @change="
                          uploadStrukturOrg($event);
                          validateFileTypeGeneral($event);
                          fileSizeChecker('10', $event);
                        "
                        required
                      ></b-form-file>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  id="nama-personil-group"
                  label="Nama Personil"
                  label-for="nama-personil"
                  description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
                >
                  <b-row>
                    <b-col cols="8">
                      <b-form-input
                        id="nama_personil"
                        v-model="form.nama_personil"
                        type="text"
                        placeholder="Nama Personil"
                      ></b-form-input
                    ></b-col>
                    <b-col cols="4">
                      <b-form-file
                        ref="file"
                        placeholder="Pilih File"
                        drop-placeholder="Drop file di sini..."
                        accept=".pdf, .doc, .docx"
                        browse-text="Cari"
                        multiple
                        :file-name-formatter="formatNames"
                        @change="
                          uploadNamaPersonil($event);
                          validateFileTypeGeneral($event);
                          fileSizeChecker('10', $event);
                        "
                      ></b-form-file>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  id="keterangan-group"
                  label="Keterangan"
                  label-for="keterangan"
                >
                  <b-form-textarea
                    id="keterangan"
                    v-model="form.keterangan"
                    placeholder="Keterangan"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
                <hr />
                <div class="d-flex justify-content-center">
                  <button
                    type="reset"
                    class="btn btn-delete mr-8"
                    @click="resetForm()"
                  >
                    Kembali
                  </button>
                  <button type="submit" class="btn btn-save">Simpan</button>
                </div>
              </b-form>
            </b-modal>

            <!--   Modal Edit Kelembagaan Provinsi  -->
            <b-modal
              v-model="showModalEdit"
              hide-footer
              size="xl"
              centered
              id="modal-edit-kabkota"
            >
              <template #modal-header="{ close }">
                <h5 class="title-card-custom">
                  Ubah Kelembagaan Kabupaten Kota
                </h5>
              </template>
              <b-form @submit.stop.prevent="onSubmitEdit">
                <b-form-group
                  id="example-input-group-3"
                  label-for="select-kabkota"
                  label="Kabupaten/Kota"
                >
                  <b-form-select
                    id="select-kabkota"
                    v-model="formEdit.kabkota"
                    :options="kabkotas"
                    disabled
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Pilih Salah Satu --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="example-input-group-badan"
                  label-for="select-badan"
                  label="Bentuk Kelembagaan"
                  label-class="required"
                >
                  <b-form-select
                    id="select-badan"
                    v-model="formEdit.badan"
                    :options="badans"
                    required
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Pilih salah satu --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="nomenklatur-group"
                  label="Nomenklatur Kelembagaan"
                  label-for="nomenklatur"
                  label-class="required"
                >
                  <b-form-input
                    id="nomenklatur"
                    v-model="formEdit.nomenklatur"
                    type="text"
                    placeholder="Nomenklatur"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="alamat-group"
                  label="Alamat Kantor"
                  label-for="alamat"
                  label-class="required"
                >
                  <b-form-textarea
                    id="alamat"
                    v-model="formEdit.alamat"
                    placeholder="Alamat Kantor"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  id="nama-pimpinan-group"
                  label="Nama Pimpinan"
                  label-for="nama_pimpinan"
                  label-class="required"
                >
                  <b-form-input
                    id="nama_pimpinan"
                    v-model="formEdit.nama_pimpinan"
                    type="text"
                    placeholder="Nama Pimpinan"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="no-telp-group"
                  label="No. Telepon/Whatsapp Kantor"
                  label-for="no_telp"
                  label-class="required"
                >
                  <b-form-input
                    id="no_telp"
                    v-model="formEdit.no_telp"
                    type="tel"
                    placeholder="No. Telp."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="email-group" label="Email Kantor" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="formEdit.email"
                    type="email"
                    placeholder="Email Kantor"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-7"
                  label="Perda Pembentukan"
                  label-for="perda_pembentukan-7"
                  description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
                  label-class="required"
                >
                  <b-row>
                    <b-col cols="8">
                      <b-form-input
                        id="perda_pembentukan"
                        v-model="formEdit.perda_pembentukan"
                        type="text"
                        placeholder="Perda Pembentukan"
                        required
                      ></b-form-input
                    ></b-col>
                    <b-col cols="4"
                      ><b-form-file
                        ref="files"
                        placeholder="Pilih File"
                        drop-placeholder="Drop file di sini..."
                        accept=".pdf, .doc, .docx"
                        browse-text="Cari"
                        multiple
                        :file-name-formatter="formatNames"
                        @change="
                          uploadPerdaPembentukanEdit($event);
                          validateFileTypeGeneral($event);
                          fileSizeChecker('10', $event)"
                        required
                      ></b-form-file
                    ></b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  id="struktur-organisasi-group"
                  label="Struktur Organisasi"
                  label-for="struktur-organisasi"
                  description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
                  label-class="required"
                >
                  <b-row>
                    <b-col cols="8">
                      <b-form-input
                        id="struktur_organisasi"
                        v-model="formEdit.struktur_organisasi"
                        type="text"
                        placeholder="Struktur Organisasi"
                        required
                      ></b-form-input
                    ></b-col>
                    <b-col cols="4">
                      <b-form-file
                        ref="file"
                        placeholder="Pilih File"
                        drop-placeholder="Drop file di sini..."
                        accept=".pdf, .doc, .docx"
                        browse-text="Cari"
                        multiple
                        :file-name-formatter="formatNames"
                        @change="
                          uploadStrukturOrgEdit($event);
                          validateFileTypeGeneral($event);
                          fileSizeChecker('10', $event)"
                        required
                      ></b-form-file>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  id="nama-personil-group"
                  label="Nama Personil"
                  label-for="nama-personil"
                  description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
                >
                  <b-row>
                    <b-col cols="8">
                      <b-form-input
                        id="nama_personil"
                        v-model="formEdit.nama_personil"
                        type="text"
                        placeholder="Nama Personil"
                      ></b-form-input
                    ></b-col>
                    <b-col cols="4">
                      <b-form-file
                        ref="file"
                        placeholder="Pilih File"
                        drop-placeholder="Drop file di sini..."
                        accept=".pdf, .doc, .docx"
                        browse-text="Cari"
                        multiple
                        :file-name-formatter="formatNames"
                        @change="
                          uploadNamaPersonilEdit($event);
                          validateFileTypeGeneral($event);
                          fileSizeChecker('10', $event)"
                      ></b-form-file>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  id="keterangan-group"
                  label="Keterangan"
                  label-for="keterangan"
                >
                  <b-form-textarea
                    id="keterangan"
                    v-model="formEdit.keterangan"
                    placeholder="Keterangan"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
                <hr />
                <div class="d-flex justify-content-center">
                  <button
                    type="reset"
                    class="btn btn-delete mr-8"
                    @click="closeModal()"
                  >
                    Kembali
                  </button>
                  <button type="submit" class="btn btn-save">Simpan</button>
                </div>
              </b-form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxButton,
  DxPaging,
  DxPager,
  DxFilterRow,
  DxScrolling
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.light.css";
import CustomStore from "devextreme/data/custom_store";
import { handleErrors, isNotEmpty } from "@/core/appUtil/util";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { mapGetters } from "vuex";
import {
  fileSizeChecker,
  validateFileTypeGeneral,
} from "@/core/helpers/functions";

let token = localStorage.getItem("token");
// let provinsi = "";
let searchQ = "";
let badanQ = "0";
let kotaQ = null;

const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      // Set default sorting
      if (i == 'sort' && !isNotEmpty(loadOptions[i])) {
        params += `${i}=[{"selector":"kabkota.name","desc":false}]&`;
      }

      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists
    if (searchQ.length > 0) {
      // params += "&nomenklatur=" + searchQ;
      if (params.indexOf("?") > -1) {
        params += "&";
      } else {
        params += "?";
      }

      params += `q=${searchQ}`;
    }

    if (badanQ !== "0") {
      params += "&bentuk_kelembagaan_id=" + badanQ;
    }
    if (kotaQ !== "0") {
      params += "&provinsi_id=" + kotaQ;
    }
    if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0 &&
      loadOptions["filter"][0] === "name"
    ) {
      params += "&nama=" + loadOptions["filter"][2];
    }

    return fetch(process.env.VUE_APP_URL_LOCAL + `/kelembagaan-kota${params}`)
      .then(response => response.json())
      .then(data => {
        if (data.data === null) {
          setTimeout(() => {
            document.querySelector("#refreshButton").click();
          }, 500);

        } else {
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount
          };
        }
      })
      .catch(() => {
        throw "Data Loading Error";
      });
  }
});
const dataGridRefKey = "kesbangpol-data-grid";
const swalDelete = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-delete",
    cancelButton: "btn btn-cancel"
  },
  buttonsStyling: false
});
const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  mixins: [fileSizeChecker, validateFileTypeGeneral],
  name: "KelembagaanKabKota",
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxPaging,
    DxPager,
    DxFilterRow,
    DxScrolling
  },
  data() {
    return {
      showModalEdit: false,
      showModalAdd: false,
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      namaProvinsi: "",
      form: {
        kabkota: null,
        badan: null,
        nomenklatur: "",
        alamat: "",
        nama_pimpinan: "",
        no_telp: "",
        email: "",
        perda_pembentukan_path: null,
        perda_pembentukan: "",
        struktur_organisasi_path: null,
        struktur_organisasi: "",
        nama_personil_path: null,
        nama_personil: "",
        keterangan: ""
      },
      formEdit: {
        kabkota: null,
        badan: null,
        nomenklatur: "",
        alamat: "",
        nama_pimpinan: "",
        no_telp: "",
        email: "",
        perda_pembentukan_path: null,
        perda_pembentukan: "",
        struktur_organisasi_path: null,
        struktur_organisasi: "",
        nama_personil_path: null,
        nama_personil: "",
        keterangan: ""
      },
      badans: [],
      provinsiId: null,
      kabkotas: [],
      provinsis: {
        name: null
      },
      loading: false,
      search: "",
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          width: "900"
        },
        title: {
          text: `Provinsi ${this.$route.params.data.provinsi.name}`,
          align: "center"
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "40%",
            endingShape: "rounded",
            distributed: true
          }
        },
        colors: [
          "#7CB5EC",
          "#434348",
          "#90ED7D",
          "#F7A35C",
          "#8085E9",
          "#F15C80",
          "#2B908F",
          "#E4D354"
        ],
        dataLabels: {
          enabled: true
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: null,
        yaxis: {
          title: {
            text: "Total"
          },
          range: 1
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + " data";
            }
          }
        }
      }
    };
  },
  mounted() {
    kotaQ = this.$route.params.data?.provinsi_id;
    if (!kotaQ) {
      this.$router.push({name: 'KelembagaanProvinsi'});
    }

    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    })
      .then(() => {
        this.getBadans()
        this.getRefKab()
        this.getRefProv()

        this.getKabKotaStats();
      })

  },
  computed: {
    ...mapGetters({
      hasPermission: "token/hasPermission"
    }),
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  methods: {
    getBadans(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL + `/master/bentuk-kelembagaan?take=100`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.data === null && attempt <= 3) {
            this.getBadans(attempt);

          } else {
            const detailMenu = items.data;
            let selectedOptions;
            selectedOptions = [];
            detailMenu.map(i => {
              return selectedOptions.push({
                text: i.name,
                value: i.id
              });
            });
            this.badans = selectedOptions;
          }
        });
    },
    getRefKab(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL +
            `/ref/kabkota?provinsi_id=${this.$route.params.data.provinsi_id}&take=100`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.data === null && attempt <= 3) {
            this.getRefKab(attempt);

          } else {
            const detailMenu = items.data;
            let selectedOptions;
            selectedOptions = [];
            detailMenu.map(i => {
              return selectedOptions.push({
                text: i.name,
                value: i.id
              });
            });
            this.kabkotas = selectedOptions;
          }
        });
    },
    getRefProv(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL +
            `/ref/provinsi/${this.$route.params.data.provinsi_id}`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.data === null && attempt <= 3) {
            this.getRefProv(attempt);

          } else {
            this.provinsis = items.data;
            this.namaProvinsi = items.data.name;
          }
        });
    },
    uploadPerdaPembentukan(event) {
      Swal.showLoading();

      let perda_pembentukan_path = event.target.files;
      let dataUpload = new FormData();
      // dataUpload.append("file", filePerda);
      for (const file of perda_pembentukan_path) {
        dataUpload.append("files", file, file.name);
      }
      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`), {
        method: "POST",
        headers: {
          Authorization: "bearer " + token
        },
        body: dataUpload
      })
        .then(response => response.json())
        .then(async dataHasilUpload => {
          Swal.close();

          this.form.perda_pembentukan_path = dataHasilUpload.data.join(";");
        });
    },
    uploadStrukturOrg(event) {
      Swal.showLoading();

      let struktur_organisasi_path = event.target.files;
      let dataUpload = new FormData();
      // dataUpload.append("file", filePerda);
      for (const file of struktur_organisasi_path) {
        dataUpload.append("files", file, file.name);
      }
      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`), {
        method: "POST",
        headers: {
          Authorization: "bearer " + token
        },
        body: dataUpload
      })
        .then(response => response.json())
        .then(async dataHasilUpload => {
          Swal.close();

          this.form.struktur_organisasi_path = dataHasilUpload.data.join(";");
        });
    },
    uploadNamaPersonil(event) {
      Swal.showLoading();

      let nama_personil_path = event.target.files;
      let dataUpload = new FormData();
      // dataUpload.append("file", filePerda);
      for (const file of nama_personil_path) {
        dataUpload.append("files", file, file.name);
      }
      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`), {
        method: "POST",
        headers: {
          Authorization: "bearer " + token
        },
        body: dataUpload
      })
        .then(response => response.json())
        .then(async dataHasilUpload => {
          Swal.close();

          this.form.nama_personil_path = dataHasilUpload.data.join(";");
        });
    },
    uploadPerdaPembentukanEdit(event) {
      Swal.showLoading();

      let perda_pembentukan_path = event.target.files;
      let dataUpload = new FormData();
      // dataUpload.append("file", filePerda);
      for (const file of perda_pembentukan_path) {
        dataUpload.append("files", file, file.name);
      }
      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`), {
        method: "POST",
        headers: {
          Authorization: "bearer " + token
        },
        body: dataUpload
      })
        .then(response => response.json())
        .then(async dataHasilUpload => {
          Swal.close();

          this.formEdit.perda_pembentukan_path = dataHasilUpload.data.join(";");
        });
    },
    uploadStrukturOrgEdit(event) {
      Swal.showLoading();

      let struktur_organisasi_path = event.target.files;
      let dataUpload = new FormData();
      // dataUpload.append("file", filePerda);
      for (const file of struktur_organisasi_path) {
        dataUpload.append("files", file, file.name);
      }
      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`), {
        method: "POST",
        headers: {
          Authorization: "bearer " + token
        },
        body: dataUpload
      })
        .then(response => response.json())
        .then(async dataHasilUpload => {
          Swal.close();

          this.formEdit.struktur_organisasi_path = dataHasilUpload.data.join(";");
        });
    },
    uploadNamaPersonilEdit(event) {
      Swal.showLoading();

      let nama_personil_path = event.target.files;
      let dataUpload = new FormData();
      // dataUpload.append("file", filePerda);
      for (const file of nama_personil_path) {
        dataUpload.append("files", file, file.name);
      }
      fetch(encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`), {
        method: "POST",
        headers: {
          Authorization: "bearer " + token
        },
        body: dataUpload
      })
        .then(response => response.json())
        .then(async dataHasilUpload => {
          Swal.close();

          this.formEdit.nama_personil_path = dataHasilUpload.data.join(";");
        });
    },
    getKabKotaStats() {
      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL +
            `/kelembagaan-kota/stats?provinsi_id=${this.$route.params.data.provinsi_id}`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          this.statsProvinsi = items.data;
          let selectedOptions;
          selectedOptions = [];
          let cats = [];
          let dats = [];
          this.statsProvinsi.map(i => {
            cats.push(i.name);
            dats.push(i.total);
            return selectedOptions.push({
              name: i.name,
              total: i.total
            });
          });
          this.series = [
            {
              name: "Jumlah",
              data: dats
            }
          ];

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: cats
              },
              labels: cats
            }
          };
        });
    },
    refreshData() {
      this.dataGrid.refresh();
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} file terpilih`;
    },
    exportToExcel() {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Statistik");

      exportDataGrid({
        component: this.dataGrid,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Statistik.xlsx"
          );
        });
      });
    },
    exportToPDF() {
      const options = {
        columnWidth: "wrap",
        margin: { horizontal: 5 },
        bodyStyles: { valign: "top" },
        styles: { overflow: "linebreak", overflowColumns: true },
        headerStyles: {
          fillColor: [51, 122, 183],
          textColor: [255],
          halign: "center",
          valign: "middle"
        },
        theme: "grid"
      };
      const doc = new jsPDF("l", "pt", "a4");
      exportDataGridToPdf({
        jsPDFDocument: doc,
        autoTableOptions: options,
        component: this.dataGrid
      }).then(() => {
        doc.save("Statistik.pdf");
      });
    },
    onSelectBadan() {
      badanQ = this.form.badan;
      this.refreshData();
    },
    async searchFilterKabKotaStats() {
      searchQ = this.search;
      let context = this;
      this.loading = true;
      this.notFound = false;
      this.show = false;
      await this.$store
        .dispatch("searchFilterKabKotaStats", this.search)
        .then(res => {
          console.log(res);
          this.refreshData();
          this.loading = false;
        })
        .catch(async function(err) {
          context.loading = false;
          await Swal.fire({
            title: err.message,
            text: "",
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Tutup",
            cancelButtonText: false
          });
        });
    },
    onSubmit() {
      let data = {
        id: null,
        nomenklatur: this.form.nomenklatur,
        kabkota_id: this.form.kabkota,
        bentuk_kelembagaan_id: this.form.badan,
        alamat: this.form.alamat,
        nama_pimpinan: this.form.nama_pimpinan,
        no_telp: this.form.no_telp,
        email: this.form.email,
        perda_pembentukan: this.form.perda_pembentukan,
        perda_pembentukan_path: this.form.perda_pembentukan_path,
        struktur_organisasi: this.form.struktur_organisasi,
        struktur_organisasi_path: this.form.struktur_organisasi_path,
        nama_personil: this.form.nama_personil,
        nama_personil_path: this.form.nama_personil_path,
        keterangan: this.form.keterangan
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/kelembagaan-kota`),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil ditambahkan",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.refreshData();
              this.getKabKotaStats();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onSubmitEdit() {
      let id = this.formEdit.id;

      let data = {
        nomenklatur: this.formEdit.nomenklatur,
        alamat: this.formEdit.alamat,
        bentuk_kelembagaan_id: this.formEdit.badan,
        kabkota_id: this.formEdit.kabkota,
        nama_pimpinan: this.formEdit.nama_pimpinan,
        no_telp: this.formEdit.no_telp,
        email: this.formEdit.email,
        perda_pembentukan: this.formEdit.perda_pembentukan,
        perda_pembentukan_path: this.formEdit.perda_pembentukan_path,
        struktur_organisasi: this.formEdit.struktur_organisasi,
        struktur_organisasi_path: this.formEdit.struktur_organisasi_path,
        nama_personil: this.formEdit.nama_personil,
        nama_personil_path: this.formEdit.nama_personil_path,
        keterangan: this.formEdit.keterangan
      };
      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(
              process.env.VUE_APP_URL_LOCAL + `/kelembagaan-kota/${id}`
            ),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil diubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetFormEdit();
              this.refreshData();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        alamat: "",
        nomenklatur: "",
        kabkota: null,
        badan: null,
        nama_pimpinan: "",
        no_telp: "",
        email: "",
        perda_pembentukan_path: null,
        perda_pembentukan: "",
        struktur_organisasi_path: null,
        struktur_organisasi: "",
        nama_personil_path: null,
        nama_personil: "",
        keterangan: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-tambah-kabkota");
      });
    },
    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-kabkota");
      });
    },
    resetFormEdit() {
      this.formEdit = {
        alamat: "",
        nomenklatur: "",
        kabkota: null,
        badan: null,
        nama_pimpinan: "",
        no_telp: "",
        email: "",
        perda_pembentukan_path: null,
        perda_pembentukan: "",
        struktur_organisasi_path: null,
        struktur_organisasi: "",
        nama_personil_path: null,
        nama_personil: "",
        keterangan: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-kabkota");
      });
    },
    onClickDelete(e) {
      let id = e.row.data.id;
      swalDelete.fire({
        text: "Yakin ingin hapus data?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(
              process.env.VUE_APP_URL_LOCAL + `/kelembagaan-kota/${id}`
            ),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              }
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.refreshData();
              this.getKabKotaStats();
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onClickEdit(e) {
      const data = e.row.data;
      this.formEdit.nomenklatur = data.nomenklatur;
      this.formEdit.alamat = data.alamat;
      this.formEdit.kabkota = data.kabkota_id;
      this.formEdit.badan = data.bentuk_kelembagaan_id;
      this.formEdit.id = data.id;
      this.formEdit.nama_pimpinan = data.nama_pimpinan;
      this.formEdit.no_telp = data.no_telp;
      this.formEdit.email = data.email;
      this.formEdit.perda_pembentukan = data.perda_pembentukan;
      this.formEdit.perda_pembentukan_path = data.perda_pembentukan_path;
      this.formEdit.struktur_organisasi = data.struktur_organisasi;
      this.formEdit.struktur_organisasi_path = data.struktur_organisasi_path;
      this.formEdit.nama_personil = data.nama_personil;
      this.formEdit.nama_personil_path = data.nama_personil_path;
      this.formEdit.keterangan = data.keterangan;

      this.$root.$emit("bv::show::modal", "modal-edit-kabkota", e.row.data);
    }
  }
};
</script>

<style>
#grid-container {
  height: 440px;
}
.width-custom {
  width: 420px;
  height: 280px;
  display: flex;
  justify-content: center;
}
.title-card {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #000000;
}
</style>
